import React from 'react';

import PageError4041 from '../../components/Page404';
export default function PageError404() {
  return (
    <>
      <PageError4041 />
    </>
  );
}
