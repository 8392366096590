import React, { lazy, Suspense, useState, useEffect, useCallback } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { BeatLoader } from 'react-spinners';
import PrivateRoute from './components/privateroute';
import localforage from 'config/localForage';
import { connect } from 'react-redux';
import { setProfilePicture } from './redux/reducers/General';

// Layout Blueprints

import { LeftSidebar, MinimalLayout } from './layout-blueprints';

// Example Pages

import PageError404 from './pages/PageError404';

const DashboardStatistics = lazy(() =>
  import('./pages/DashboardStatistics/index')
);
const PageLoginBasic = lazy(() => import('./pages/PageLoginBasic'));
const PageRecoverBasic = lazy(() => import('./pages/PageRecoverBasic'));
const UserList = lazy(() => import('./pages/Master/User'));
const UserEdit = lazy(() => import('./pages/Master/User/edit'));
const EmailList = lazy(() => import('./pages/Master/Email'));
const EmailEdit = lazy(() => import('./pages/Master/Email/edit'));
const Profile = lazy(() => import('./pages/Profile'));
const AccountExport = lazy(() =>  import('./pages/AccountExport/index'));
// const LaporanEdit = lazy(() =>  import('./pages/Master/AssignDomba/importExcel'));
const Routes = (props) => {
  const { setProfilePicture } = props;
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  const SuspenseLoading = () => {
    return (
      <>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <BeatLoader color={'#3c44b1'} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Loading
            {/* <span className="font-size-lg d-block text-dark">
              This live preview instance can be slower than a real production
              build!
            </span> */}
          </div>
        </div>
      </>
    );
  };

  const [user, setUser] = useState(null);
  const [mounted, setMounted] = useState(false);

  const getUser = useCallback(async () => {
    try {
      const value = await localforage.getItem('user');
      // This code runs once the value has been loaded
      // from the offline store.
      // console.log("user private", value);
      setUser(value);
      setMounted(true);
      setProfilePicture(value.pict);
    } catch (err) {
      // This code runs if there were any errors.
      console.log(err);
    }
  }, [setProfilePicture]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  if (mounted) {
    return (
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            {!user && <Redirect exact from="/" to="/login" />}
            {!user && <Redirect exact from="/master" to="/login" />}
            {user && <Redirect exact from="/" to="/dashboard" />}
            {user && <Redirect exact from="/login" to="/dashboard" />}
            {/* <Route path={['/Overview']}>
              <PresentationLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/Overview" component={Overview} />
                  </motion.div>
                </Switch>
              </PresentationLayout>
            </Route> */}

            <Route path={['/dashboard', '/master', '/report', '/profile']}>
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <PrivateRoute
                      user={user}
                      path="/dashboard"
                      component={DashboardStatistics}
                    />
                    <PrivateRoute
                      user={user}
                      path="/profile"
                      component={Profile}
                    />
                    <PrivateRoute
                      user={user}
                      path="/master/user/list"
                      component={UserList}
                    />
                    <PrivateRoute
                      user={user}
                      path="/master/user/edit"
                      component={UserEdit}
                    />
                    <PrivateRoute
                      user={user}
                      path="/master/email/list"
                      component={EmailList}
                    />
                    <PrivateRoute
                      user={user}
                      path="/master/email/edit"
                      component={EmailEdit}
                    />
                    <PrivateRoute
                      user={user}
                      exact path="/report/mph"
                      component={AccountExport}
                    />
                    {/* <PrivateRoute
                      user={user}
                      exact path="/master/admin"
                      component={Admin}
                    />
                    <PrivateRoute
                      user={user}
                      exact path="/master/admin/edit"
                      component={AdminEdit}
                    /> */}
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>

            {/* <Route
              path={[
                '/PageCalendar',
                '/PageChat',
                '/PageProjects',
                '/PageFileManager',
                '/PageProfile'
              ]}>
              <CollapsedSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/PageCalendar" component={PageCalendar} />
                    <Route path="/PageChat" component={PageChat} />
                    <Route path="/PageProjects" component={PageProjects} />
                    <Route path="/PageFileManager" component={PageFileManager} />
                    <Route path="/PageProfile" component={PageProfile} />
                  </motion.div>
                </Switch>
              </CollapsedSidebar>
            </Route> */}

            <Route path={['/login', '/forgotpassword', '/notfound']}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/login" component={PageLoginBasic} />
                    <Route
                      path="/forgotpassword"
                      component={PageRecoverBasic}
                    />
                    <Route path="/notfound" component={PageError404} />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>
            <Route component={PageError404} />
          </Switch>
        </Suspense>
      </AnimatePresence>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => ({
  rProfilePicture: state.General.profilePicture
});

const mapDispatchToProps = (dispatch) => ({
  setProfilePicture: (pp) => dispatch(setProfilePicture(pp))
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
