let urlConfig =
  process.env.NODE_ENV !== 'production'
    ? {
        urlBackend: 'http://localhost:8090/',
        urlBackendProfile: 'http://localhost:8090/serve/profile//'
      }
    : {
        urlBackend: 'https://mphapi.gerejadijakarta.com/',
        urlBackendProfile: 'https://mphapi.gerejadijakarta.com/serve/profile//',
      };

export default urlConfig;
