/* eslint-disable */
import React, { useState } from 'react';

import clsx from 'clsx';

import { Collapse } from 'reactstrap';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../redux/reducers/ThemeOptions';

import {
  ChevronRight,
  Shield,
  Database,
  Mail,
  Award,
  User
} from 'react-feather';

const SidebarMenu = (props) => {
  const { setSidebarToggleMobile, sidebarUserbox } = props;

  const toggleSidebarMobile = () => setSidebarToggleMobile(false);

  const [masterOpen, setMasterOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const toggleMaster = (event) => {
    setMasterOpen(!masterOpen);
    event.preventDefault();
  };
  const toggleReport = (event) => {
    setReportOpen(!reportOpen);
    event.preventDefault();
  };

  return (
    <>
      <PerfectScrollbar>
        {/* {sidebarUserbox && <SidebarUserbox />} */}
        <div className="sidebar-navigation">
          {/* <div className="sidebar-header">
            <span>Navigation menu</span>
          </div> */}
          <ul>
            <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/dashboard">
                <span className="sidebar-icon">
                  <Shield />
                </span>
                Dashboard
                {/* <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span> */}
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/master/user/list">
                <span className="sidebar-icon">
                  <User />
                </span>
                User
                {/* <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span> */}
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/master/email/list">
                <span className="sidebar-icon">
                  <Mail />
                </span>
                Email Laporan Harian
                {/* <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span> */}
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/report/mph">
                <span className="sidebar-icon">
                  <Award />
                </span>
                Minat Persembahan Harta
                {/* <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span> */}
              </NavLink>
            </li>
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
