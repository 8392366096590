import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, user, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        // console.log("user in private", user);
        if (user === null) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }

        // check if route is restricted by role
        // if (roles && roles.indexOf(role) === -1) {

        // if (roles) {
        //     // role not authorised so redirect to home page
        //     let matchRole = roles.find(x => x == token.token.role);
        //     if (!matchRole) {

        //         if (token.token.role == "Administrator" || token.token.role == "Internal") {

        //             return <Redirect to={{ pathname: '/bo/dashboard' }} />
        //         } else {
        //             if (token.token.role == "Penatua") {
        //                 return <Redirect to={{ pathname: '/bo/dashboardpenatua' }} />
        //             } else {
        //                 return <Redirect to={{ pathname: '/bo/dashboardft' }} />
        //             }
        //         }
        //     }
        // }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
